import { Route, Switch, withRouter } from "react-router-dom";

import { Home } from "../pages/home";
import { Portfolio } from "../pages/portfolio";
import { ContactUs } from "../pages/contact";
import { About } from "../pages/about";
import { Socialicons } from "../components/socialicons";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

const AnimatedSwitch = withRouter(({ location }) => (
  <AnimatePresence mode='wait'>
    <Switch location={location}>
      <Route exact path='/' component={Home} />
      <Route path='/about' component={About} />
      <Route path='/portfolio' component={Portfolio} />
      <Route path='/contact' component={ContactUs} />
      <Route path='*' component={Home} />
    </Switch>
  </AnimatePresence>
));

function AppRoutes() {
  return (
    <div className='s_c'>
      <AnimatedSwitch />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
