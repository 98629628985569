const logotext = "Caroline Tran";
const meta = {
  title: "Caroline Tran - Ostéopathe",
  description: "Caroline Tran - Osteopathe DE",
  keywords: "Caroline Tran, Ostéopathe, Boulogne Billancourt",
};

const introdata = {
  title: "Caroline Tran - Ostéopathe",
  animated: {
    first: "Ostéopathie",
    second: "Désirs de grossesse",
    third: "Nourrissons",
    fourth: "Femmes enceintes",
    fifth: "Sportifs",
  },
  description: "Diplômée du CEESO",
  your_img_url: "https://images.unsplash.com/photo-1552196563-55cd4e45efb3",
};

const dataabout = {
  title: "L'Ostéopathie",
  aboutme: `C’est Andrew Taylor Still (1828-1917) qui créera l’ostéopathie. Fils de médecin et prêcheur protestant méthodiste, il est originaire du Kansas.
Alors que la guerre frappe le pays, il note que la mortalité infantile est plus faible dans les régions où la densité de médecins est moindre. Still perd trois de Ses enfants d’une méningite cérébrale spinale. S’en suit alors, un désir fort de soigner plus efficacement.  Il s’appuiera alors sur ses connaissances de l’anatomie ainsi que celles sur la physiologie humaine afin de développer la fameuse ostéopathie.

Il abandonnera par la suite la médecine allopathique afin de se consacrer à sa philosophie de « chercher la cause, retirer l’obstruction et laisser le remède de la nature, le sang artériel être le docteur. » De cette manière de pensée, naitrons alors les principes ostéopathiques :
La structure gouverne la fonction
Le principe d'unité du corps humain,
La loi de l'artère est suprême,
Le principe d'auto-guérison du corps humain.

Suite aux encourageants résultats de son approche ostéopathique, Still fonde l’American School of osteopathy à Kirksville , qui sera alors la toute première école d’ostéopathie.
`,
};

const dataCab = {
  title: "Mon approche",
  aboutme: `De nombreuses techniques ostéopathiques sont enseignées. Elles diffèrent selon les écoles, selon les enseignants, et un fois diplômés, les ostéopathes affineront eux mêmes leur sens du toucher de différentes manières.
Nous développons un sens, et ressentons une perception unique et propre à nous même.

Pour ma part, j’ai façonné ma pratique au fil des années, et celle ci est désormais essentiellement constituée de techniques douces. Ma philosophie de l’ostéopathie a pour visée de traiter les causes, et donc prendre un compte le patient dans son intégrité physique, mais également dans son intégrité psychique et émotionnelle.

Ne considérant pas le corps dans une simple dimension mécaniste, les techniques de crac articulaires sont très peu utilisées au cabinet. Manipuler un étage vertébral, sans traiter la cause, aboutira simplement à une meilleure mobilité temporaire jusqu’à ce que les vertèbres, toujours soumises aux mêmes contraintes, retrouvent leur position originelle et redeviennent douloureuses ou dérangeantes.
`,
};

const worktimeline = [
  {
    jobtitle: "Lundi",
    date: "9h - 20h",
  },
  {
    jobtitle: "Mardi",
    date: "9h - 20h",
  },
  {
    jobtitle: "Mercredi",
    date: "Fermé",
  },
  {
    jobtitle: "Jeudi",
    date: "9h - 20h",
  },
  {
    jobtitle: "Vendredi",
    date: "9h - 20h",
  },
  {
    jobtitle: "Samedi",
    date: "9h - 20h",
  },
  {
    jobtitle: "Dimanche",
    date: "Fermé",
  },
];

const skills = [
  {
    name: "Python",
    value: 90,
  },
  {
    name: "Djano",
    value: 85,
  },
  {
    name: "Javascript",
    value: 80,
  },
  {
    name: "React",
    value: 60,
  },
  {
    name: "Jquery",
    value: 85,
  },
];

const services = [
  {
    title: "Diplôme d'ostéopathe D.O du CEESO Paris",
    date: "2018",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title:
      "Allaitement, modelage et portage - Optimiser le confort quotidien du bebé par l'ostéopathie",
    date: "2019",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Les troubles de la machoîre - l'ATM dans tous ses états",
    date: "2019",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Kinesio Taping Fundamental and advanced (KT1/KT2)",
    date: "2019",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Approche intégrative de la fertilité en ostéopathie",
    date: "2020",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
  {
    title: "Suivi de grossesse et préparation à l'accouchement",
    date: "2022",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
  },
];

const dataportfolio = [
  {
    img: "https://picsum.photos/400/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/800/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/600/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },

  {
    img: "https://picsum.photos/400/600/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/550/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    desctiption:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
];

const contactConfig = {
  YOUR_EMAIL: "name@domain.com",
  YOUR_FONE: "(555)123-4567",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  facebook:
    " https://www.facebook.com/people/Caroline-TRAN-Ostéopathe-DO/100054655921332/",
  instagram: "https://www.instagram.com/ctran.osteo/",
};
export {
  meta,
  dataabout,
  dataCab,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
