import "./style.css";
import * as Dialog from "@radix-ui/react-dialog";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import { meta } from "../../content_option";
import { motion } from "framer-motion/dist/framer-motion";
import useArticles from "../../hooks/useArticles";
import { useState } from "react";

export const Portfolio = () => {
  const { isLoading, error, data } = useArticles();
  const [selectedArticle, setSelectedArticle] = useState(null);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  if (data) {
    return (
      <HelmetProvider>
        <motion.div
          initial='hidden'
          animate='visible'
          exit={{ opacity: 0, transition: { duration: 1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        >
          <Container className='About-header'>
            <Helmet>
              <meta charSet='utf-8' />
              <title> Articles | {meta.title} </title>{" "}
              <meta name='description' content={meta.description} />
              <meta name='keywords' content={meta.keywords} />
            </Helmet>
            <Row className='mb-5 mt-3'>
              <Col lg='8'>
                <h1 className='display-4 mb-4'> Articles </h1>{" "}
                <hr className='t_border my-4 ml-0 text-left' />
              </Col>
            </Row>
            <div className='mb-5 po_items_ho'>
              {data?.map((article, i) => {
                return (
                  <div
                    key={i}
                    className='po_item'
                    onClick={() => setSelectedArticle(article)}
                  >
                    <img src={article.image_url} alt={article.title} />
                    <div className='content'>
                      <p>{article.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Container>

          <Dialog.Root
            open={!!selectedArticle}
            onOpenChange={(isOpen) => {
              if (!isOpen) {
                setSelectedArticle(null);
              }
            }}
          >
            <Dialog.Portal>
              <Dialog.Overlay className='DialogOverlay' />
              <Dialog.Content className='DialogContent'>
                <Dialog.Title className='DialogTitle'>
                  {selectedArticle?.title}
                </Dialog.Title>
                <Dialog.Description className='DialogDescription'>
                  <ReactMarkdown>{selectedArticle?.content}</ReactMarkdown>
                </Dialog.Description>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </motion.div>
      </HelmetProvider>
    );
  }
};
