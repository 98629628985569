import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { motion } from "framer-motion/dist/framer-motion";
import { Link } from "react-router-dom";
import Cover from "../../assets/images/cover2.jpeg";

export const Home = () => {
  return (
    <HelmetProvider>
      <motion.div
        className=''
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <section id='home' className='home'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> {meta.title}</title>
            <meta name='description' content={meta.description} />
            <meta name='keywords' content={meta.keywords} />
          </Helmet>
          <div className='intro_sec d-block d-lg-flex align-items-center '>
            <div
              className='h_bg-image order-1 order-lg-2 h-100 '
              style={{ backgroundImage: `url(${Cover})` }}
            ></div>
            <div className='text order-2 order-lg-1 h-100 d-lg-flex justify-content-center'>
              <div className='align-self-center '>
                <div className='intro mx-auto'>
                  <h2 className='mb-1x'>{introdata.title}</h2>
                  <h1 className='fluidz-48 mb-1x'>
                    <Typewriter
                      options={{
                        strings: [
                          introdata.animated.first,
                          introdata.animated.second,
                          introdata.animated.third,
                          introdata.animated.fourth,
                          introdata.animated.fifth,
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 10,
                      }}
                    />
                  </h1>
                  <p className='mb-1x'>{introdata.description}</p>
                  <div className='intro_btn-action pb-5'>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.doctolib.fr/osteopathe/boulogne-billancourt/caroline-tran'
                    >
                      <div id='button_p' className='ac_btn btn'>
                        Prendre rendez-vous
                        <div className='ring one'></div>
                      </div>
                    </a>
                    <Link to='/portfolio' className='text_2'>
                      <div id='button_h' className='ac_btn btn '>
                        Articles
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </HelmetProvider>
  );
};
