import { useQuery } from "react-query";
import supabase from "../utils/supabase";

const getArticles = async () => {
  const { data, error } = await supabase.from("Articles").select();

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("No articles");
  }

  return data;
};

export default function useArticles() {
  return useQuery("user", () => getArticles());
}
