import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion/dist/framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  dataCab,
  meta,
  worktimeline,
  services,
} from "../../content_option";

export const About = () => {
  return (
    <HelmetProvider>
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <Container className='About-header'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> À propos | {meta.title}</title>
            <meta name='description' content={meta.description} />
            <meta name='keywords' content={meta.keywords} />
          </Helmet>
          <Row className='mb-5 mt-3'>
            <Col lg='8'>
              <h1 className='display-4 mb-4'>À propos</h1>
              <hr className='t_border my-4 ml-0 text-left' />
            </Col>
          </Row>
          <Row className='sec_sp'>
            <Col lang='5'>
              <h3 className='color_sec py-4'>Le cabinet</h3>
            </Col>
            <Col lg='7'>
              <div className='service_ py-4'>
                <p className='service_desc bol'>43, Boulevard Jean Jaurès</p>
                <p className='service_desc'>92100 Boulogne Billancourt</p>
                <p className='service_desc bol'>0608473177</p>
                <a href='https://www.doctolib.fr/osteopathe/boulogne-billancourt/caroline-tran'>
                  <p className='service_desc bol highlight'>
                    Prendre rendez-vous
                  </p>
                </a>
              </div>
            </Col>
          </Row>
          <Row className='sec_sp'>
            <Col lg='5'>
              <h3 className='color_sec py-4'>{dataabout.title}</h3>
            </Col>
            <Col lg='7' className='d-flex align-items-center'>
              <div>
                <p>{dataabout.aboutme}</p>
              </div>
            </Col>
          </Row>
          <Row className='sec_sp'>
            <Col lg='5'>
              <h3 className='color_sec py-4'>{dataCab.title}</h3>
            </Col>
            <Col lg='7' className='d-flex align-items-center'>
              <div>
                <p>{dataCab.aboutme}</p>
              </div>
            </Col>
          </Row>
          <Row className=' sec_sp'>
            <Col lg='5'>
              <h3 className='color_sec py-4'>Horaires</h3>
            </Col>
            <Col lg='7'>
              <table className='table caption-top'>
                <tbody>
                  {worktimeline.map((data, i) => {
                    return (
                      <tr key={i}>
                        <th scope='row'>{data.jobtitle}</th>
                        <td>{data.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className='sec_sp'>
            <Col lang='5'>
              <h3 className='color_sec py-4'>Formations</h3>
            </Col>
            <Col lg='7'>
              {services.map((data, i) => {
                return (
                  <div className='service_ py-4' key={i}>
                    <h5 className='service__title'>
                      <span className='blo'>{data.date}</span> - {data.title}
                    </h5>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </motion.div>
    </HelmetProvider>
  );
};
